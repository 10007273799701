<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-6 offset-md-6">
                <b-input-group class="mt-3">
                    <b-form-input v-model="payload.username" placeholder="Search by username or email"  />
                    <b-input-group-append>
                    <b-button variant="primary" @click="getData()">Search user</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>

        <b-table v-if="loading == false" class="mt-3" responsive :items="withdraws.data" :fields="fields">
            <template #cell(username)="row">
                {{ row.item.user ? row.item.user.username : '' }}
            </template>
            <template #cell(email)="row">
                {{ row.item.user ? row.item.user.email : '' }}
            </template>
            <template #cell(membership)="row">
                {{ row.item.membership_user && row.item.membership_user.membership ? row.item.membership_user.membership.name : '' }}
            </template>
            <template #cell(quantity)="row">
                {{ row.item.quantity }}
            </template>
            <template #cell(available)="row">
                <span v-if="row.item.membership_user">
                    {{ calcEarns(row.item.membership_user.months, row.item.membership_user.price) }}
                </span>
                <span v-else>
                    {{ row.item.user.available }}
                </span>
            </template>
            <template #cell(wallet)="row">
               {{ row.item.wallet }}
            </template>
            <template #cell(status)="row">
               {{ row.item.status == '0' ? 'Created' : row.item.status == 1 ? 'Approved' : 'Cancel' }}
            </template>
            <template #cell(options)="row">
                <b-button variant="primary" size="sm" @click="activePackage(row.item.id)">
                    Reply Request
                </b-button>
            </template>
        </b-table>
        <Loading type="table" v-else/>
        <div class="text-right">
            <b-button-group>
                <b-button variant="outline-primary" @click="payload.page = payload.page - 1" :disabled="withdraws.meta.current_page == withdraws.meta.first_page">Preview</b-button>
                <b-button variant="outline-primary" @click="payload.page = payload.page + 1" :disabled="withdraws.meta.current_page == withdraws.meta.last_page">Next</b-button>
            </b-button-group>
        </div>
        <b-modal size="lg" title="Reply Request" v-model="isReply" centered hide-footer>
            <div class="row">
                <div class="col-12">
                    <span>Reply notes</span>
                    <b-form-input v-model="answer.notes" placeholder="Enter reply notes" />
                </div>
                <div class="col-12 mt-3 text-right">
                    <b-badge @click="answer.status = 1" :variant="answer.status == 1 ? 'primary' : 'outline-primary' " pill class="p-3 mr-3 click">
                        Approve
                    </b-badge>
                    <b-badge @click="answer.status = 2" :variant="answer.status == 2 ? 'primary' : 'outline-primary'" pill class="p-3 click">
                        Cancel
                    </b-badge>
                </div>
                <div class="col-12 mt-4 text-right">
                    <b-button @click="isReply = false" size="lg" class="mr-3">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="lg" @click="onSubmit">
                        Reply Request
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
    data () {
        return {
            loading: false,
            payload: {
                page: 1,
                username: null
            },
            total: 0,
            fields: [
                {tdClass: 'text-left', thClass: 'text-left', key: 'username', label: 'Username' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'email', label: 'Email' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'membership', label: 'Membership' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'quantity', label: 'Quantity' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'available', label: 'Available' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'wallet', label: 'Wallet' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'status', label: 'Status' },
                {tdClass: 'text-left', thClass: 'text-left', key: 'options', label: 'Options' },
            ],
            modalEdit: false,
            modalPurchase: false,
            tempUser: {
                id: null
            },
            answer: {
                id: null,
                notes: null,
                status: null
            },
            isReply: false
        }
    },
    created () {
        this.getData()
    },
    methods: {
        ...mapActions('withdraw', ['getWithdrawsAdmin', 'updateWithdraw']),
        calcEarns (months, price) {
            let percentage = 0
            if(months == '1') {
                percentage = 7;
            } else if (months == '3') {
                percentage = 11;
            } else if (months == '15') {
                percentage = 15
            } else if (months == '12') {
                percentage = 17
            }
            let percentages = percentage * months

            let total = price + (price * (percentages / 100))
            total = total.toFixed(2)
            
            return total
        },
        getData() {
            this.loading = true
            this.getWithdrawsAdmin(this.payload).then(response => {
                this.loading = false
                this.total = response
            })
        },
        onSubmit() {
            this.loading = true
            this.updateWithdraw(this.answer).then(() => {
                this.loading = false
                this.isReply = false
                this.getData()
            })
        },
        activePackage(id) {
            this.answer.id = id
            this.isReply = true
        }
    },
    computed: {
        ...mapState('withdraw', ['withdraws'])
    },
    watch: {
        'payload.page': function (val) {
            this.payload.page = val
            this.getData()
        }
    }
}
</script>